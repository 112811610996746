/* eslint-disable no-extra-parens */
/* eslint-disable id-match */
import React, {Component, useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Black} from 'wobi-web-common/dist/Colors';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Dialog,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Backdrop,
  Paper,
  Hidden,
} from '@material-ui/core';
import {Breadcrumbs, EzFormControl, CallMePopover, YesNoRadioGroup, Loader} from 'wobi-web-common';
import * as yup from 'yup';
import {Form, Formik} from 'formik';
import {FormattedMessage, injectIntl} from 'react-intl';
import {agentDeveloperUtils} from 'wobi-web-common/dist/components/utils/helpers';
import productTypes from '../enums/product-type';
import {mapRequestData} from '../utils/offerFieldsActions';
import {checkOfferStatus, getCoversTitles, getAllOffers, handleContactUsSubmit} from '../utils/apiHandlers';
import {getOffersFields, setOffersFields} from '../utils/persistOfferDetails';
import Offer from '../components/Offer';
import {fireGtmEvent} from '../utils/marketing.utils';
import {checkFullCoverIsProtected,
  updateForceAbandonmentStepForNewLeadsInSessionStorageOffersFields,
  updateForceTriggeredByInSessionStorageOffersFields} from '../utils/common';
import fullDataSteps from '../config/steps';
import CallMeFooter from '../components/CallMeFooter';
import {TRIGGERED_BY, abandonmentStep} from '../constants/enums';
import {getImageByAgent} from '../config/agent';
import {sortingOptions} from './offers/offersSort';
import OffersQuickActions from './offers/OffersQuickActions';
import NoOffers from './offers/NoOffers';

// import { Block, FullscreenExit, Visibility } from '@material-ui/icons';

const LoaderCarImage = getImageByAgent('loader-car.gif');
const handelingFeeIcon = getImageByAgent('handelingFee_icon.png');

const CHECK_STATUS_TIMEOUT = process.env.REACT_APP_CHECK_STATUS_TIMEOUT || 1000;
const CHECK_STATUS_TIME_LIMIT = sessionStorage.getItem('REACT_APP_CHECK_STATUS_TIME_LIMIT') ||
                                process.env.REACT_APP_CHECK_STATUS_TIME_LIMIT || 3000;

sessionStorage.setItem('REACT_APP_CHECK_STATUS_TIME_LIMIT', CHECK_STATUS_TIME_LIMIT);
const CHECK_STATUS_DELAY = process.env.REACT_APP_CHECK_STATUS_DELAY || 1;

// const isUsingCarHandlingFee = agentDeveloperUtils.isUsingCarHandlingFee();
const steps = fullDataSteps.reduce((curry, step) => {
  curry.push(step.label);
  return curry;
}, []);

class OffersStep extends Component {
  constructor(props) {
    super(props);
    this.handler = this.handler.bind(this);
    this.getTitles = this.getTitles.bind(this);
    this.checkStatus = this.checkStatus.bind(this);
    this.getOffers = this.getOffers.bind(this);
    this.getNewOffers = this.getNewOffers.bind(this);
    this.setOpenOffer = this.setOpenOffer.bind(this);
    this.toggleContactUs = this.toggleContactUs.bind(this);
    this.submitContactUs = this.submitContactUs.bind(this);
    this.toggleHendlingFeeVisability = this.toggleHendlingFeeVisability.bind(this);

    // this.handleMouseExit = this.handleMouseExit.bind(this);
  }

  state = {
    containDiscountOffer: false,
    coversTitles: {},
    doneLoadingOffers: false,
    handelingFeeMessegeVisability: false,
    highestCoverage: 0,
    isContactUsLoading: false,
    isTypeChangeDialogOpen: false,
    isUsingCarHandlingFee: false,

    // offers: mockOffers,
    keepChecking: true,
    loader: false,
    lowestPrice: 0,
    lowParticipation: 0,
    offers: [],
    offersCallId: '',
    offersFields: getOffersFields(),
    openOfferIndex: 0,
    showContactUs: false,
    showContactUsThanks: false,
    sortTypeId: 1,
  };

  handler(actionType, values) {
    switch (actionType) {
    case 'sort': {
      const sortTypeId = values;
      const comparer = sortingOptions.find(sortingOption => sortingOption.id === sortTypeId).comparer;

      this.setState(prevState => ({
        offers: prevState.offers.sort(comparer),
        sortTypeId,
      }));
      break;
    }
    case 'drivers': {
      setOffersFields(values);
      this.getOffers();
      this.setState({offersFields: getOffersFields()});
      break;
    }
    case 'type': {
      if (values === productTypes.FullCover) {
        this.setState({isTypeChangeDialogOpen: true});
      } else {
        setOffersFields({policyType: values});
        this.getNewOffers();
        this.setState({offersFields: getOffersFields()});
      }
      break;
    }
    default:
      break;
    }
  }

  toggleHendlingFeeVisability() {
    this.setState({handelingFeeMessegeVisability: !this.state.handelingFeeMessegeVisability});
  }

  handleMouseExit() {
    this.setState({handelingFeeMessegeVisability: false});
  }

  setOpenOffer(index) {
    this.setState({openOfferIndex: index});
  }

  constactUsSubmitHandler() {
    console.log('aaa');
  }

  getTitles() {
    getCoversTitles(getOffersFields('policyType')).then((resp) => {
      if (resp && resp.data) {
        this.setState({coversTitles: resp.data.coversTitles});
      }
    });
  }

  checkStatus() {
    checkOfferStatus({
      offersCallId: this.state.offersCallId,
    }).then(resp => {
      if (resp.data && resp.data.responses && resp.data.responses.length) {
        const respOffers = resp.data.responses;
        this.setState({
          highestCoverage: respOffers
            .reduce((highestCoverage, {additionalPrices}) => (!highestCoverage ?
              additionalPrices[0].value : additionalPrices[0].value > highestCoverage ?
                additionalPrices[0].value : highestCoverage), 0),
          lowestPrice: respOffers
            .reduce((lowestPrice, {price}) => (!lowestPrice ?
              price : price < lowestPrice ?
                price : lowestPrice), 0),
          lowParticipation: respOffers
            .reduce((lowParticipation, {additionalPrices}) => (!lowParticipation ?
              additionalPrices[1].value : additionalPrices[1].value < lowParticipation ?
                additionalPrices[1].value : lowParticipation), 0),

          offers: respOffers,
        }, () => {
          this.handler('sort', this.state.sortTypeId);
        });
      }
    });
  }

  initTimer() {
    this.setState({
      keepChecking: true,
    }, () => {
      setTimeout(() => {
        this.statusInterval = setInterval(() => {
          if (this.state.keepChecking) {
            this.checkStatus();
          } else {
            this.setState({
              doneLoadingOffers: true,
              keepChecking: false,
              loader: false,
            });
            clearInterval(this.statusInterval);
          }
        }, CHECK_STATUS_TIMEOUT);
      }, CHECK_STATUS_DELAY);
      this.timeout = setTimeout(() => {
        this.setState({});
        this.setState({
          doneLoadingOffers: true,
          keepChecking: false,
          loader: false,
        });
        clearInterval(this.statusInterval);
      }, CHECK_STATUS_TIME_LIMIT);
    });
  }

  static generateOrderId() {
    return `${moment().format('mmHHDDMM')}`;
  }

  getOffers() {
    updateForceAbandonmentStepForNewLeadsInSessionStorageOffersFields(abandonmentStep.ABANDONMENT_STEP_2.key);
    this.setState({
      keepChecking: true,
      loader: true,
      offers: [],
    });
    const requestData = mapRequestData(getOffersFields());

    this.setState({offers: []}, () => {
      getAllOffers(requestData).then(resp => {
        if (resp.data) {
          const offerCallIdState = {offersCallId: resp.data.offersCallId};
          setOffersFields(offerCallIdState);
          sessionStorage.setItem('orderId', OffersStep.generateOrderId());
          this.setState(offerCallIdState);
          this.setState({isUsingCarHandlingFee: agentDeveloperUtils.isUsingCarHandlingFee()});
        }
        this.initTimer();
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.keepChecking === true && this.state.keepChecking === false && this.state.offers.length) {
      this.findDiscount();
    }
  }

  getNewOffers() {
    clearInterval(this.statusInterval);
    clearTimeout(this.timeout);
    this.setState({
      isTypeChangeDialogOpen: false,
      openOfferIndex: 0,
    });
    this.getOffers();
  }

  submitContactUs() {
    this.setState({
      isContactUsLoading: true,
    });
    fireGtmEvent('ContactusNoOffersButton');
    updateForceAbandonmentStepForNewLeadsInSessionStorageOffersFields(
      abandonmentStep.ABANDONMENT_STEP_2_NO_SUGGESTION.key,
    );
    updateForceTriggeredByInSessionStorageOffersFields(TRIGGERED_BY.WEBSITE_NO_OFFERS);
    handleContactUsSubmit({
      fullname: `${getOffersFields('firstname')} ${getOffersFields('lastname')}`,
      isFromNoOffers: true,
      offersCallId: getOffersFields('offersCallId'),
      phone: getOffersFields('phone'),
    }, getOffersFields(), TRIGGERED_BY.WEBSITE_NO_OFFERS).then(() => {
      this.setState({
        isContactUsLoading: false,
        showContactUsThanks: true,
      });
    });
  }

  toggleContactUs(status) {
    this.setState({showContactUs: status});
  }

  componentDidMount() {
    this.getOffers();
    this.getTitles();
  }

  findDiscount = () => {
    // eslint-disable-next-line no-unused-vars
    for (const offer of this.state.offers) {
      if (offer.cascoAfterDiscountPrice && !this.state.containDiscountOffer) {
        this.setState({containDiscountOffer: true});
        break;
      }
    }
  };

  render() {
    const {classes, intl} = this.props;
    const messages = (id) => intl.formatMessage({id});

    const tagsArray = ['מגבלת קילומטרים'];
    const OffersContainer = (
      <React.Fragment>
        <OffersQuickActions criteriaChanged={this.handler} offersFields={this.state.offersFields} />
        <div className={`${classes.offersStepOffers}`}>
          {
            this.state.offers.filter(checkFullCoverIsProtected).map((offer, index) => (
              <Offer titles={this.state.coversTitles}
                key={`offer-${index}`} setIsOpen={() => this.setOpenOffer(index)}
                openContactUs={() => this.toggleContactUs(true)}
                closeContactUsHandler={() => this.toggleContactUs(false)}
                expandCoversHandler={() => fireGtmEvent('ShowAllCoverage', {provider: offer.provider})}
                isBestOffer={
                  this.state.sortTypeId === 1 ? offer.price === this.state.lowestPrice :
                    this.state.sortTypeId === 2 ? offer.additionalPrices[0].value === this.state.highestCoverage :
                      this.state.sortTypeId === 3 ? offer.additionalPrices[1].value === this.state.lowParticipation :
                        false
                }
                bestOfferBadgeText={messages(`offers.bestOfferBadgeText[${this.state.sortTypeId}]`)}
                showContactUs={index === this.state.openOfferIndex && this.state.showContactUs}
                data={{
                  ...offer,
                  additionalInfoPolicyPDFlink: `/policies/${offer.productLineId}/AdditionalInfoPolicy.pdf`,
                  pdfLink: `/policies/${offer.productLineId}/policy.pdf`,
                }}
                isOpen={this.state.openOfferIndex === index}
                tags={offer.kmLimit ? tagsArray : []}
                pdfLinkHandler={() => fireGtmEvent('ShowPDFpolicy', {provider: offer.provider})}
              />
            ))
          }
          {this.state.doneLoadingOffers && (
            <CallMeFooter/>
          )}
        </div>
      </React.Fragment>
    );

    const periodString = () => `תקופת ביטוח ${moment(getOffersFields('startDate'), 'DD/MM/YYYY').format('D.M.YYYY')}` +
      ` - ${moment(getOffersFields('endDate'), 'DD/MM/YYYY').format('D.M.YYYY')}`;

    const autoString = () => {
      if (!this.state.offersFields.autoInfo) {
        return '';
      }
      const {manufacturer, main_car_desc, car_year} = this.state.offersFields.autoInfo;
      const {licenseNumber} = this.state.offersFields;

      return `ל${manufacturer} ${main_car_desc} שנת ${car_year} ${licenseNumber ? 'מספר ' + licenseNumber : ''}`;
    };

    return (
      <section>
        {
          this.state.offers.length ?
            <React.Fragment>
              <Hidden only='xs'>
                <React.Fragment>
                  <div className={classes.summaryText}>
                    <FormattedMessage id='offers.number_of_offers_text' values={{
                      name: getOffersFields('firstname'),
                      number_of_offers: this.state.offers.length,
                    }} />
                  </div>
                  <div className={classes.SummaryTitles}>
                    <h5><FormattedMessage id='offers.new_clients_only'/></h5>
                    <h5>
                      {this.state.isUsingCarHandlingFee &&
                      <FormattedMessage id='offers.include_handling_fees' />}
                      {this.state.isUsingCarHandlingFee &&
                      <img className='handelingFeeIcon' src={handelingFeeIcon} alt='i icon'
                        onMouseEnter={this.toggleHendlingFeeVisability}
                        onMouseLeave={this.toggleHendlingFeeVisability}
                      />}
                      {this.state.isUsingCarHandlingFee && this.state.handelingFeeMessegeVisability &&
                      <FormattedMessage className='handelingFeeMessage' id='offers.car_hendeling_fee' />}
                    </h5>
                    {this.state.containDiscountOffer && <h5><FormattedMessage id='offers.digital_discount'/></h5>}
                  </div>
                </React.Fragment>
              </Hidden>
              <Hidden smUp>
                <React.Fragment>
                  <div className={classes.summaryText}>
                    <div className='bg'/>
                    <span>{getOffersFields('firstname')}</span>
                    <FormattedMessage id='offers.number_of_offers_text2' values={{
                      number_of_offers: this.state.offers.length,
                    }} />
                  </div>
                  <Breadcrumbs currentStep={1} subStepPercentage={0} steps={steps}
                    className={classes.innerBreadcrumbs}/>
                  <div className={classes.SummaryTitles}>
                    <h5>{periodString()}</h5>
                    <h5>{autoString()}</h5>
                    <h5><FormattedMessage id='offers.new_clients_only'/></h5>
                    <h5>
                      {this.state.isUsingCarHandlingFee &&
                      <FormattedMessage id='offers.include_handling_fees' />}
                      {this.state.isUsingCarHandlingFee &&
                      <img className='handelingFeeIcon' src={handelingFeeIcon} alt='i icon'
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                        role='button'
                        tabIndex={0}
                        onClick={this.toggleHendlingFeeVisability}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            this.toggleHendlingFeeVisability();
                          }
                        }}
                      />}
                    </h5>
                    <h5>
                      {this.state.isUsingCarHandlingFee && this.state.handelingFeeMessegeVisability &&
                      <FormattedMessage className='handelingFeeMessage' id='offers.car_hendeling_fee' />}
                    </h5>
                    {this.state.containDiscountOffer && <h5><FormattedMessage id='offers.digital_discount'/></h5>}
                  </div>
                </React.Fragment>
              </Hidden>

              {Number(this.state.offersFields.year) <= 2010 ? (
                <h5 className={classes.disclaimer}>
                  <Hidden only='xs'>
                    <FormattedMessage id='offers.esp_disclaimer' />
                  </Hidden>
                  <Hidden smUp>
                    <FormattedMessage id='offers.esp_disclaimer_mobile' />
                  </Hidden>
                </h5>
              ) : null}
            </React.Fragment> :
            false
        }
        <div className={`${classes.offersStep}`}>
          <Backdrop className={classes.backdrop} open={this.state.loader}>
            <Paper style={{padding: 30}}>
              <Loader image={LoaderCarImage}>
                מגוון הצעות בדרך...
              </Loader>
            </Paper>
          </Backdrop>
          {
            this.state.offers.length ? OffersContainer :
              !this.state.keepChecking && !this.state.offers.length ? (
                <NoOffers
                  classes={classes}
                  isContactUsLoading={this.state.isContactUsLoading}
                  showContactUsThanks={this.state.showContactUsThanks}
                  submitContactUs={this.submitContactUs}
                />
              ) : false
          }
          {this.state.containDiscountOffer && <h5><FormattedMessage id='offers.discount_disclaimer'/></h5>}
          <Dialog onClose={() => this.setState({isTypeChangeDialogOpen: false})}
            aria-labelledby='simple-dialog-title'
            open={this.state.isTypeChangeDialogOpen}
          >
            <FullcoverMissingFieldsForm getOffers={this.getNewOffers} />
          </Dialog>
        </div>
      </section>
    );
  }
}

OffersStep.propTypes = {
  classes: PropTypes.object,
  intl: PropTypes.object,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

const useStyles = theme => ({
  backdrop: {
    zIndex: 9,
  },
  contactUsThanks: {
    '& > div': {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.pxToRem(15.75),
      marginLeft: 20,
    },
    '& img': {
      width: 80,
    },
    alignItems: 'center',
    border: `1px solid ${agentDeveloperUtils.getConvertedColor(Black[1])}`,
    borderRadius: 10,
    display: 'inline-flex',

    margin: '20px 0',

    padding: '15px 40px',
  },
  disclaimer: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(17.5),
    fontWeight: 500,
    margin: '0 0 20px',
    [theme.breakpoints.down(768)]: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.43,
      margin: '0 16px 20px',
      position: 'relative',
    },
  },
  FullcoverMissingFieldsForm: {
    '& .msg': {
      color: theme.palette.secondary.main,
      fontSize: theme.typography.pxToRem(14.9),
      fontWeight: 600,
      lineHeight: 1.2,
      marginBottom: 20,
    },

    padding: 50,
  },
  innerBreadcrumbs: {
    [theme.breakpoints.up(769)]: {display: 'none'},
    '& .MuiStepper-root': {
      marginBottom: 16,
      padding: 0,
      position: 'relative',
    },
  },
  loaderContainer: {
    '&.hide': {
      margin: 0,
      maxHeight: 0,
      transition: 'max-height 0.75s ease-out',
    },
    maxHeight: 300,
    overflow: 'hidden',

    transition: 'max-height 0.75s ease-in',
  },
  mainCarTooltip: {
    [theme.breakpoints.up(1550)]: {marginLeft: 20},
    '& .icon': {height: 42},
  },
  mainCarTooltipBubble: {
    maxWidth: 220,
  },
  newClients: {
    margin: '5px 0 20px',
    [theme.breakpoints.down(768)]: {margin: '5px 16px 20px'},
  },
  noOffers: {
    '& .contact_us_loader': {
      margin: '30px 70px',
    },

    '& .img-container': {
      '& img': {
        maxWidth: 478,
        width: '100%',
      },
      marginTop: 50,
    },

    '& .text': {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.pxToRem(21),
    },

    '& button': {
      display: 'flex',
      fontWeight: 700,
      justifyContent: 'space-between',
      marginTop: 30,
    },

    padding: 10,
  },
  offersStep: {
    margin: '0 auto',
    maxWidth: 1180,
  },
  offersStepOffers: {
    '&>*:not(:last-child)': {
      marginBottom: 15,
    },
    backgroundColor: theme.palette.background.default,
    borderRadius: 12,
    [theme.breakpoints.down(768)]: {
      backgroundColor: 'transparent',
      borderRadius: 0,
      padding: '15px 0',
    },
    padding: 15,
  },
  summaryText: {
    [theme.breakpoints.down(768)]: {
      '& .bg': {
        background: theme.palette.type === 'light' ?
          'linear-gradient(180deg, #ECF9FE 0%, #FBFEFF 180px, #FFFFFF52 200px) 0% 0% no-repeat padding-box' :
          'linear-gradient(180deg, #272733 0%, #3b3b42 180px, #424242 200px) 0% 0% no-repeat padding-box',
        height: '200px',
        pointerEvents: 'none',
        position: 'absolute',
        right: -26,
        top: -16,
        width: '100vw',
        zIndex: -1,
      },
      '& > span': {fontWeight: 700},
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 500,
      letterSpacing: '-0.5px',
      lineHeight: 1.2,
      margin: '0 16px',
      position: 'relative',
      top: -16,
      zIndex: 0,
    },
    color: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(24.5),
    fontWeight: 600,
    margin: '0 16px',
    [theme.breakpoints.up(769)]: {margin: 0},
  },
  SummaryTitles: {
    '& .handelingFeeIcon': {
      marginRight: '5px',
    },
    '& h5': {
      color: theme.palette.text.secondary,
      display: 'flex',
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 500,
      lineHeight: 1.43,
      margin: '5px 0',
    },
    '& img': {
      height: '1.43em',
      marginLeft: '5px',
      width: 'auto',
      zIndex: 1,
    },
    [theme.breakpoints.down(768)]: {
      '& h5': {
        fontSize: theme.typography.pxToRem(14),
        margin: '0 16px',
      },
      marginBottom: 16,
      position: 'relative',
    },
  },
  withTooltip: {
    margin: [[0, 0, 10, 0]],
  },
});

const FullcoverMissingFieldsForm = withStyles(useStyles)(injectIntl(({classes, intl, getOffers}) => {
  const messages = id => intl.formatMessage({id});
  const [callMeOpen, setSetCallMeOpen] = useState(false);

  const AnchorEl = () => (<div id='popoverAnchor' />);

  const handleCallMeClose = () => {
    setSetCallMeOpen(false);
  };
  const handleCallMeSubmit = (values) => {
    console.log(values);
  };

  const updateOffersFields = (values) => {
    const offersFields = getOffersFields();
    const updatedFields = {};

    // insCovers ---
    // Have full ins history but not in 4
    if (offersFields.hasFullInsPast === 'yes' && Number(values.insCover4) !== 1) {
      updatedFields.insCovers = {};
      updatedFields.insCovers[1] = 1;
      updatedFields.insCovers[2] = 1;
      updatedFields.insCovers[3] = 1;
      updatedFields.insCovers[4] = Number(values.insCover4);
      updatedFields.hasFullInsPast = 'no';
    }

    // Doesn't have full ins history
    if (offersFields.hasFullInsPast === 'no') {
      updatedFields.insCovers = {...offersFields.insCovers};
      updatedFields.insCovers[4] = values.insCover4;
    }

    // // Claims

    // Have at least 2 claims
    if (values.propClaim === 'yes' && values.bodyClaim === 'yes') {
      setSetCallMeOpen(true);
      return true;
    }

    // Property claims ---
    // commented by ticket DEL-1755
    // if (values.propClaim === 'yes') {
    //   // Has one claim on forth and one in the last 3
    //   if (offersFields.propClaimInLast3Years === 1) {
    //     setSetCallMeOpen(true);
    //     return true;
    //   } else {
    //     updatedFields.propClaimInLast3Years = 1;
    //     updatedFields.propClaimInYear = 4;
    //   }
    // }

    // Body claims
    // if (values.bodyClaim === 'yes') {
    //   // Has one claim on forth and one in the last 3
    //   if (offersFields.bodyClaimInLast3Years === 1) {
    //     setSetCallMeOpen(true);
    //     return true;
    //   } else {
    //     updatedFields.bodyClaimInLast3Years = 1;
    //     updatedFields.bodyClaimInYear = 3;
    //   }
    // }
    updatedFields.haveAnotherCar = values.haveAnotherCar;
    updatedFields.policyType = productTypes.FullCover;
    setOffersFields(updatedFields);
    return false;
  };

  const overriddenStyles = {
    inlineLabel: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      width: '97%',
    },
  };

  const callMeid = callMeOpen ? 'call-me-popover' : undefined;
  return (
    <Formik
      onSubmit={(values) => {
        const isBlocked = updateOffersFields(values);
        if (isBlocked) {
          return;
        }
        getOffers();
      }}
      validationSchema={yup.object().shape({
        haveAnotherCar: yup.string().required(<FormattedMessage id='validation.required' />),
        insCover4: yup.string().required(<FormattedMessage id='validation.required' />),
        propClaim: yup.string().required(<FormattedMessage id='validation.required' />),
      })}
      initialValues={{
        haveAnotherCar: '',
        insCover4: '',
        propClaim: '',
      }}>
      {({values, handleChange}) => (
        <Form className={classes.FullcoverMissingFieldsForm}>
          <div className='msg'>{messages('offers.in_order_to_change')}</div>
          <EzFormControl name='insCover4' label={messages('offers.had_insurance_4years_ago')}
            isInlineLabel={false}>
            <RadioGroup onChange={handleChange('insCover4')} row>
              <FormControlLabel value='1' control={<Radio />}
                label={<FormattedMessage id='insurance_history.label_fullcover' />} />
              <FormControlLabel value='2' control={<Radio />}
                label={<FormattedMessage id='insurance_history.label_3rdparty' />} />
              <FormControlLabel value='0' control={<Radio />}
                label={<FormattedMessage id='insurance_history.label_no_insurance' />} />
            </RadioGroup>
          </EzFormControl>
          <EzFormControl
            name='haveAnotherCar'
            label={messages('auto_info.second_car')}
            isInlineLabel
            overriddenStyles={overriddenStyles}
          >
            <YesNoRadioGroup value={values.haveAnotherCar} onChange={handleChange} />
          </EzFormControl>
          <EzFormControl name='propClaim' label={messages('offers.had_prop_claim_year_4')}
            isInlineLabel={false}>
            <YesNoRadioGroup value={values.propClaim} onChange={handleChange} />
          </EzFormControl>
          <Button type='submit'>{messages('offers.get_offers')}</Button>
          <CallMePopover id={callMeid} anchorEl={document.querySelector('#popoverAnchor')} open={callMeOpen}
            onClose={handleCallMeClose}
            onSubmit={handleCallMeSubmit}
            message={messages('insurance_history.call_me_message')} />
          <AnchorEl />
        </Form>
      )}
    </Formik>
  );
}));

export default withStyles(useStyles)(injectIntl(OffersStep));
