import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Typography, Button, Container} from '@material-ui/core';
import {useIntl} from 'react-intl';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

const RidersDisclaimer = ({onClick, signatureRequired}) => {
  const classes = useStyles();
  const intl = useIntl();
  const messages = (id) => intl.formatMessage({id});
  return (
    <Container className={classes.container}>
      <Typography variant='h5' className={classes.title}>
        {messages('simulator.callSummary.ridersDisclaimer.title')}
      </Typography>
      <div style={{float: 'left'}}>
        <Button variant='contained' onClick={() => onClick('EMAIL_SMS')} color='primary' className={classes.button}>
          {signatureRequired ?
            messages('simulator.callSummary.ridersDisclaimer.EmailAndSms.button') :
            messages('simulator.callSummary.ridersDisclaimer.button')}
        </Button>
        {signatureRequired ?
          (<Button
            style={{right: '10px', textTransform: 'none'}}
            variant='contained'
            onClick={() => onClick('WHATSAPP')}
            color='primary'
            className={classes.button}
          >
            {messages('simulator.callSummary.ridersDisclaimer.Whatsapp.button')}
          </Button>) :
          null}

      </div>
    </Container>
  );
};

RidersDisclaimer.propTypes = {
  onClick: PropTypes.func.isRequired,
  signatureRequired: PropTypes.bool,
};

export default RidersDisclaimer;
