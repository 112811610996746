import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Route, Switch} from 'react-router-dom';
import {Redirect} from 'react-router';
import {Layout, Breakpoints as breakpoints, PageNotFound} from 'wobi-web-common';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {agentDeveloperUtils} from 'wobi-web-common/dist/components/utils/helpers';
import {
  DetailsRoute,
  GettingStartedRoute,
  OffersRoute, OverUseRoute,
  PreferredOffersRoute,
  CallSummaryRoute,
  CreditGuardRoute,
  routes,
  SimulatorRoute, ThankYouRoute, AskToContactRoute,
} from '../config/routes';
import ThankYouSmsPage from '../steps/ChurnThankYouPage';
import Simulator from '../steps/Simulator';
import PreferredOffers from '../steps/SimulatorPreferredOffers';
import CallSummary from '../steps/SimulatorCallSummary';
import GettingStatedStep from '../steps/GettingStartedStep';
import DetailsStep from '../steps/DetailsStep';
import OffersStep from '../steps/OffersStep';
import OrderApproval from '../steps/OrderApproval';
import OverUse from '../steps/OverUse';
import CreditGuard from '../steps/simulator/CreditGurad';
import {setOffersFields} from '../utils/persistOfferDetails';
import {getAgentDeveloperName} from '../utils/common';
import AskToContactThankYouPage from '../steps/AskToContactThankYouPage';
import SideBar from './SideBar';
import Header from './Header';

const Wrapper = styled.div`
  height: 100%;
  @media screen and (max-width: ${breakpoints.mobile}px) {
    padding: ${({narrowPadding}) => narrowPadding ? '0 10px' : '0 25px'};
  }
`;

const RedirectWithStatus = ({from, to, status}) => (
  <Route
    render={({staticContext}) => {
      if (staticContext) {
        staticContext.status = status;
      }
      return <Redirect from={from} to={to} />;
    }}
  />
);

RedirectWithStatus.propTypes = {
  from: PropTypes.any,
  status: PropTypes.any,
  to: PropTypes.any,
};

const Routes = ({location}) => {
  const [showSidebar, setShowSidebar] = React.useState(false);
  const [showOnlyBaseHeader, setShowOnlyBaseHeader] = React.useState(false);
  const [showHeader, setShowHeader] = React.useState(true);
  const [narrowPadding, setNarrowPadding] = React.useState(false);
  const [isOtpFirstInUrl, setIsOtpFirstInUrl] = React.useState(false);
  const [hasRedirected, setHasRedirected] = React.useState(false);
  useEffect(() => {
    agentDeveloperUtils.replaceWobiWithAgentName();
  });

  useEffect(() => {
    // save the agentName in the session storage
    const agentDeveloperName = getAgentDeveloperName();
    setOffersFields({agentDeveloperName});
  }, []);

  useEffect(() => {
    const currentRoute = routes.find(route => route.pathname === location.pathname);
    setShowSidebar(currentRoute ? Boolean(currentRoute.showSidebar) : false);
    setShowHeader(currentRoute ? Boolean(currentRoute.showHeader) : true);
    setShowOnlyBaseHeader(currentRoute ? Boolean(currentRoute.showOnlyBaseHeader) : true);
    setNarrowPadding(currentRoute ? Boolean(currentRoute.narrowPadding) : false);
    setIsOtpFirstInUrl(agentDeveloperUtils.isOtpFirstInUrl(location));
  }, [location]);

  const redirectOnOtpFirst = () => {
    sessionStorage.setItem('maxStepThatComplete', 2);
    setOffersFields({otp: 'first'});
    sessionStorage.setItem('landingParameters', sessionStorage.getItem('offers_fields'));
    window.location.href = `${DetailsRoute.pathname}`;
    setHasRedirected(true);
  };
  useEffect(() => {
    if (isOtpFirstInUrl && !hasRedirected) {
      redirectOnOtpFirst();
    }
  }, [isOtpFirstInUrl, hasRedirected, location.pathname]);

  const rewriteURL = () => `/getting-started${location.search}${
    location.pathname && location.pathname.toLowerCase().includes('3rdparty') ? '#3RDPARTY' : location.hash}`;

  const isMobile = useMediaQuery(`(max-width:${breakpoints.mobile}px)`);

  return (<Switch>
    <Route exact path={SimulatorRoute.pathname} component={Simulator} />
    <Route exact path='/contact-me/:potentialId/:retryMessage?' component={ThankYouSmsPage} />
    <Route exact path='/wobi-contact-me/:potentialId/:thirdMessage?' component={ThankYouSmsPage} />
    <Route exact path='/contact-me-for-offers/:potentialId/:lastYearOtp?' component={ThankYouSmsPage} />
    <Route exact path={AskToContactRoute.pathname} component={AskToContactThankYouPage} />

    <Route path='/initial-details'>
      <Redirect to={rewriteURL()} />
    </Route>
    <Layout
      sidebar={showSidebar ? <SideBar /> : false}
      header={
        showHeader ?
          <Header
            isMobile={isMobile}
            hasContactus={showSidebar && isMobile}
            hasChatButton
            showOnlyBaseHeader={showOnlyBaseHeader}
          /> :
          false
      }
    >

      <Wrapper narrowPadding={narrowPadding}>
        <Switch>
          <Route exact path={CallSummaryRoute.pathname} component={CallSummary} />
          <Route exact path={PreferredOffersRoute.pathname} component={PreferredOffers} />
          <Route exact path={CreditGuardRoute.pathname} component={CreditGuard} />
          <Route exact path={GettingStartedRoute.pathname} component={GettingStatedStep} />
          <Route exact path={DetailsRoute.pathname} component={DetailsStep} />
          <Route exact path={OffersRoute.pathname} component={OffersStep} />
          <Route exact path={ThankYouRoute.pathname} component={OrderApproval} />
          <Route exact path={OverUseRoute.pathname} component={OverUse} />
          <Route exact path='/'>
            <Redirect to='/getting-started' />
          </Route>
          <Route exact path='/ביטוח-רכב/'>
            <RedirectWithStatus status={302} to='/getting-started' />
          </Route>
          <Route exact path='/ביטוח-נסיעות-לחול' component={() => {
            window.location.href = 'https://travel.wobi.co.il';
            return null;
          }}/>
          <Route exact path='/ביטוח-דירה' component={() => {
            window.location.href = 'https://www.wobi.co.il/ביטוח-דירה/';
            return null;
          }}/>
          <Route exact path='/הלוואה' component={() => {
            window.location.href = 'https://my.wobi.co.il/loans/loans-Step1';
            return null;
          }}/>
          <Route exact path='*' component={PageNotFound} />
        </Switch>
      </Wrapper>
    </Layout>
  </Switch>);
};

Routes.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
  }),
};

export default Routes;
