import React from 'react';
import PropTypes from 'prop-types';
import {useIntl, FormattedMessage} from 'react-intl';
import {
  Grid,
  makeStyles,
} from '@material-ui/core';
import Moment from 'moment';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  behaveMargin: {
    paddingLeft: '10px',
  },
  boldTitle: {
    color: theme.palette.secondary.main,
  },
  documentsWarning: {
    fontSize: 'inherit',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  mainTitle: {
    color: theme.palette.secondary.main,
    fontSize: 36,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: 1,
    margin: 0,
    marginBottom: 20,
  },
  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  modalBtn: {
    fontSize: 18,
    height: 52,
    margin: '0 20px 40px',
    minWidth: '150px',
  },
  modalDialog: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '32px',
    maxHeight: 'calc(100% - 64px)',
    maxWidth: '600px',
    overflowY: 'auto',
    position: 'relative',
  },
  textHighlight: {
    display: 'contents',
    fontSize: '20px',
    fontWeight: 500,
  },
}));

const CallSummerySign = (props) => {
  const hasMandatory = props.potential.offersFields.paymentMandatory !== undefined;
  const intl = useIntl();
  const messages = (id, values) => intl.formatMessage({id}, values);
  const externalRiders = props.products.filter(product => product.isExternalRider === true);
  const notExternalRiders = props.products.filter(product => product.isExternalRider === false);
  const isExternalRiders = externalRiders.length > 0;
  let notExternalRidersTotalPrice = 0;
  notExternalRiders.forEach(product => {
    notExternalRidersTotalPrice += product.price;
  });
  return (
    <Grid>
      <h3>{messages('simulator.callSummerySign.title')}</h3>

      <div>
        <ul>
          <li>
            <FormattedMessage
              id='simulator.callSummerySign.policyBullet'
              values={{
                cascoType: props.potential.offersFields.policyType === 'FULLCOVER' ? 'מקיף' : 'צד ג׳',
                endDate: Moment(props.potential.offersFields.insuranceEndDate).format('DD/MM/YYYY'),
                insuraceCompanyName: props.potential.offersFields.insuranceCompanyName,
                mandatoryText: hasMandatory ? 'פוליסת חובה +' : null,
                startDate: Moment(props.potential.offersFields.insuranceStartDate).format('DD/MM/YYYY'),
              }} />
          </li>
          <li>
            <FormattedMessage
              id='simulator.callSummerySign.policyTotal'
              values={{
                totalPrice: props.potential.opportunity.Amount,
              }} />
          </li>
          { hasMandatory && <li>
            <FormattedMessage
              id='simulator.callSummerySign.policyMandatory'
              values={{
                mandatoryPayments: props.potential.offersFields.paymentMandatory.numberMandatoryPayments,
                mandatoryPrice: props.potential.mainProduct.mandatory.price,
              }} />
          </li>}
          <li>
            <FormattedMessage
              id='simulator.callSummerySign.policyCasco'
              values={{
                cascoPayments: props.potential.offersFields.paymentCasco.numberCascoPayments,
                cascoPrice: props.potential.mainProduct.casco.price + notExternalRidersTotalPrice,
                cascoType: props.potential.offersFields.policyType === 'FULLCOVER' ? 'מקיף' : 'צד ג׳',
              }} />
          </li>
          <li>
            <FormattedMessage
              id='simulator.callSummerySign.policyHandlingFee'
              values={{
                handlingFee: props.potential.offersFields?.handlingFeePrice,
              }} />
          </li>
          {externalRiders.map((externalrider, index) => (
            <li key={index}>
              <FormattedMessage
                id='simulator.callSummerySign.policyRider'
                values={{
                  riderName: externalrider.name,
                  riderPrice: externalrider.price,
                }}
              />
            </li>
          ))}
          <li>
            <FormattedMessage
              id='simulator.callSummerySign.creditDetailes'
              values={{
                shagrir: isExternalRiders ?
                  'חיוב לשגריר בגין כתבי שירות' :
                  null,
              }} />
          </li>
        </ul>
      </div>
      <div>
        <h3>
          <FormattedMessage
            id='simulator.callSummerySign.importantPoints'
          />
        </h3>
        <ol>
          <li>
            <FormattedMessage
              id='simulator.callSummerySign.importantPoint1' />
          </li>
          <li>
            <FormattedMessage
              id='simulator.callSummerySign.roadServicePoint2'
              values={{
                roadServiceCompany: props.potential.offersFields.roadServicesSelectedCompany,
              }} />
          </li>
          <li>
            <FormattedMessage
              id='simulator.callSummerySign.policyStartNote' />
          </li>
          {props.fullDisclosure && <li>
            <FormattedMessage
              id='simulator.callSummerySign.disclosure'
              values={{
                disclosure: props.fullDisclosure,
              }} />
          </li>}
          {props.documentsWarning && <li>
            <FormattedMessage
              id='simulator.callSummerySign.documentsWarning'
              values={{
                documentsWarning: props.documentsWarning,
              }} />
          </li>}
          {props.potential.offersFields?.mortgageCompany && <li>
            <FormattedMessage
              id='simulator.callSummerySign.mortgaged'
              values={{
                martgageCompany: props.potential.offersFields?.mortgageCompany,
              }} />
          </li>}
        </ol>
      </div>
      <div>
        <FormattedMessage
          id='simulator.callSummerySign.conclution' />
      </div>

    </Grid>
  );
};

CallSummerySign.propTypes = {
  callSummaryText: PropTypes.string,
  companyLogo: PropTypes.string,
  covers: PropTypes.array,
  disableError: PropTypes.func,
  disclaimer: PropTypes.string,
  documentsWarning: PropTypes.string,
  drivers: PropTypes.object,
  errorCodes: PropTypes.array,
  errorMessage: PropTypes.string,
  errorResponse: PropTypes.string,
  fullDisclosure: PropTypes.string,
  handleSendPDF: PropTypes.func,
  isCascoAndMandatory: PropTypes.bool,
  isSentPdf: PropTypes.bool,
  kaskoInsuranceCompany: PropTypes.string,
  onBackToCrm: PropTypes.func,
  onConfirm: PropTypes.func,
  openModal: PropTypes.bool,
  potential: PropTypes.object,
  productLineName: PropTypes.string,
  products: PropTypes.object,
  productTotalPrice: PropTypes.number,
  protectionText: PropTypes.string,
  showAlert: PropTypes.bool,
};

export default CallSummerySign;
