import React from 'react';
import PropTypes from 'prop-types';
import {OtpPopup, agentDeveloperUtils} from 'wobi-web-common';
import {getOffersFields, setOffersFields} from '../utils/persistOfferDetails';
import {getToken, validateSecret} from '../utils/apiHandlers';
import {fireGtmEvent, fireGtmEventWithUserId} from '../utils/marketing.utils';

const OtpContainer = ({
  open,
  handleClose,
  handleVerified,
  handleBlocked,
  otpMethods,
  showNID,
}) => {
  const [tokenReceived, setTokenReceived] = React.useState(false);
  const [tokenLoading, setTokenLoading] = React.useState(false);
  const [verifyLoading, setVerifyLoading] = React.useState(false);
  const [commonError, setCommonError] = React.useState('');
  const [token, setToken] = React.useState(false);
  const retryCount = React.useRef(0);

  const handlePostOtpDetails = details => {
    // make request here
    fireGtmEvent('SendOtp', {OtpType: details.otpMethod});

    fireGtmEvent('Experiment', {
      value: getOffersFields('experimentVariant') || '0',
    });
    setTokenLoading(true);
    details.webOTP = true;
    details.agentDeveloperName = agentDeveloperUtils.getAgentDeveloperName;

    // Check if Israeli National ID field is present
    if (details.nID) {
      setOffersFields({
        ownerIdentificationNumber: details.nID,
      });
    }

    getToken({...details, retryCount: retryCount.current})
      .then(res => {
        setOffersFields({
          firstname: details.fullname.split(' ')[0],
          lastname: details.fullname.split(' ')[1],
          otpMethod: details.otpMethod,
          phone: details.phoneNumber,
        });
        if (res.success) {
          setToken(res.data);
          setOffersFields({
            token: res.data,
          });
          setTokenLoading(false);
          setTokenReceived(true);
        } else if (!res.success && res.message === 'blocked user') {
          fireGtmEvent('OtpBlocked');
          handleClose();
          handleBlocked();
          setTokenLoading(false);
        }
        return null;
      })
      .catch(() => {
        setTokenLoading(false);
        setCommonError('Something wrong on details request');
      });
  };

  const handleVerify = data => {
    // make request here
    const request = Object.assign({}, {secret: data.code, token});
    setVerifyLoading(true);
    validateSecret(request)
      .then(res => {
        setVerifyLoading(false);
        if (res.success) {
          fireGtmEventWithUserId(getOffersFields('phone'), 'ConfirmOtp', {OtpType: getOffersFields('otpMethod')});
          handleClose();
          handleVerified();
        } else if (!res.success && res.message === 'wrongOtp') {
          setCommonError('קוד לא נכון אנא וודא את מספר הטלפון');
        } else {
          fireGtmEvent('OtpInvalid');
          console.log('pop validation');
        }
      })
      .catch(() => {
        setVerifyLoading(false);
        setCommonError('Something wrong on details request');
      });
  };

  const handleResend = (otpMethod) => {
    retryCount.current++;
    fireGtmEvent('SendAgainPassword', {OtpType: otpMethod});
    window.rollbar.warning(`SendAgainPassword ${otpMethod} request was fired`);
    getToken({
      agentDeveloperName: agentDeveloperUtils.getAgentDeveloperName,
      fullname: `${getOffersFields('firstname')} ${getOffersFields(
        'lastname',
      )}`,
      otpMethod,
      phoneNumber: getOffersFields('phone'),
      retryCount: retryCount.current,
      webOTP: true,
    })
      .then(res => {
        if (res.success) {
          setToken(res.data);
          setOffersFields({token: res.data});
        }
      })
      .catch(() => {
        setCommonError('Something wrong on details request');
      });
  };

  const handleBack = () => {
    setTokenReceived(false);
  };

  return (
    <OtpPopup
      open={open}
      tokenReceived={tokenReceived}
      commonErrorMessage={commonError}
      onClose={handleClose}
      handlePostOtpDetails={handlePostOtpDetails}
      handleVerify={handleVerify}
      handleResend={handleResend}
      handleBack={handleBack}
      otpMethods={otpMethods}
      tokenLoading={tokenLoading}
      verifyLoading={verifyLoading}
      showNID={showNID}
    />
  );
};

OtpContainer.propTypes = {
  handleBlocked: PropTypes.func,
  handleClose: PropTypes.func,
  handleVerified: PropTypes.func,
  open: PropTypes.bool,
  otpMethods: PropTypes.array,
  showNID: PropTypes.bool,
};

OtpContainer.defaultProps = {
  showNID: false,
};

export default OtpContainer;
